html {
  font-size: 62.5%;
}

html body #root .App {
  min-width: 100vh;
  height: 100%;
}

.content {
  min-height: 55vh;
  position: relative;
  background-color: #e7ebff;
}

.appLoading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(46, 77, 101, 0.5);
}

.phone {
  display: none;
}

.ant-modal-header {
  border-radius: 15px 15px 0 0;
}

.ant-modal-content {
  border-radius: 15px;
}

.ant-menu-submenu-popup > .ant-menu {
  color: #2e4765;
  font-size: 16px;
  background: #fff;
  border: 1px solid #e2e7ff;
  box-shadow: 20px 19px 20px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
}

.ant-menu-submenu-popup > .ant-menu .ant-menu-item {
  display: flex;
  align-items: center;
}

.subIcon {
  width: 28px;
  height: 28px;
  line-height: 25px;
  text-align: center;
  background: #e7ebff;
  border-radius: 50%;
  display: inline-block;
}

.dropdownAccount > .ant-dropdown-menu {
  border: 1px solid #1890ff;
  padding: 15px 10px;
  border-radius: 10px;
}

.header-account-dropdown-menu-item {
  background: #e7ebff;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media only screen and (max-width: 750px) {
  .phone {
    display: block;
  }
}

@media only screen and (max-width: 750px) {
  .pc {
    display: none;
  }
}

@media only screen and (max-width: 800px) {
  .shareModal .ant-modal-body {
    padding: 2px 24px;
    height: 360px;
  }
}
